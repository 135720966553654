import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Enquiry = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    // Fetch country data from the API
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countryData = response.data.map(country => ({
          code: country.cca2,
          name: country.name.common
        }));
        setCountries(countryData);
      })
      .catch(error => {
        console.error('Error fetching country data:', error);
      });

    window.onload = function () {
      // Get the form element
      var form = document.getElementById('enquiryForm');

      // Reset the form, clearing all input values
      form.reset();
    };

  }, []);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const validateEmail = () => {
    // Get the email input and error message elements
    var emailInput = document.getElementById('email');
    var emailError = document.getElementById('emailError');

    // Define the regular expression for email validation
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the input value matches the email pattern
    if (emailRegex.test(emailInput.value)) {
      // Valid email, clear the error message
      emailError.textContent = '';
    } else {
      // Invalid email, show an error message
      emailError.textContent = 'Invalid email address';
    }
  }
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Enquire Now" />

      <div className="pb-4">
        <form id="enquiryForm" action="https://formsubmit.co/45324d01f5e99f51ac067895dd740978" method="POST" >
          <h2 className="font-semibold text-lg underline">Export / Import Business Enquiry Form - </h2>
          <br />
          {/* <!-- Company Information --> */}
          <div>
            <label for="companyName">Company Name:</label>
            <input type="text" id="companyName" name="companyName" />
          </div>

          <div>
            <label for="contactPerson">Contact Person:</label>
            <input type="text" id="name" name="name" required />
          </div>

          {/* <!-- Contact Information --> */}
          <div>
            <label for="email">Email:</label>
            <input type="email" id="email" name="email" onChange={validateEmail} required />
            <p id="emailError" className="error"></p>
          </div>

          <div>
            <label for="phone">Phone:</label>
            <input type="tel" id="phone" name="phone" />
          </div>

          <div>
            <label for="phone">Country:</label>
            <select
              id="country"
              name="country"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              <option value="" disabled>Select a country</option>
              {countries.map(country => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
            {selectedCountry && <p>You selected: {selectedCountry}</p>}

          </div>
          {/* <!-- Enquiry Details --> */}
          <div>
            <label for="product">Product of Interest:</label>
            <input type="text" id="product" name="product" />
          </div>

          <div>
            <label for="quantity">Quantity in Tone:</label>
            <input type="number" id="quantity" name="quantity" />
          </div>

          <div>
            <label for="message">Additional Details:</label>
            <textarea id="message" name="message" rows="4" ></textarea>
          </div>

          {/* <!-- File Upload (Optional) --> */}
          {/* 
          <div>
            <label for="file">Attach any relevant documents:</label>
            <input type="file" id="file" name="file" />
          </div> */}
          <input type="hidden" name="_captcha" value="false"></input>
          <input type="hidden" name="_next" value="https://rkglobaltrader.com"></input>
          <button className="bg-primeColor text-white" type="submit">Submit Enquiry</button>
        </form>
        <div className="hidden flex flex-col mdl:flex-row justify-end py-4 px-4 items-center gap-2 mdl:gap-0">
          <div className="w-96 flex gap-4 flex justify-center">
            <Link to="/">
              <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4  hover:bg-black duration-300">
                Explore More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
