import { Link } from "react-router-dom";
import React, {useEffect } from 'react';
import BestSellers from "../../components/home/BestSellers/BestSellers";
import Heading from "../../components/home/Products/Heading";

const Thanks = () => {
  useEffect(() => {

  }, []);

  return (
    <div className="max-w-container mx-auto px-4 pt-1">
      <Heading heading="You May Also Like - " />

      <div className="pb-4 pt-5 flex flex-col mdl:flex-row justify-center">
        <div className="thank-you-page  ">
          <h1 className="text-5xl">Thanks for contacting us!</h1>
          <p className="pt-1 pb-1">Your enquiry has been submitted successfully.</p>
          <p>Our team will get back to you soon.</p>
          <div className=" flex flex-col mdl:flex-row justify-center py-4 px-4 items-center gap-2 mdl:gap-0">
          <div className="w-96 flex gap-4 flex justify-center">
            <Link to="/">
              <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4  hover:bg-black duration-300">
                Home
              </button>
            </Link>
          </div>
        </div>
        </div>
      </div>
      <BestSellers />

    </div>
  );
};

export default Thanks;
