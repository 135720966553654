import React, { useState } from "react";
import {
  FaFacebook,
  FaYoutube,
  FaLinkedin,
  FaWhatsapp,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaInstagram
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import FooterListTitle from "./FooterListTitle";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const location = useLocation();

  // const emailValidation = () => {
  //   return String(emailInfo)
  //     .toLocaleLowerCase()
  //     .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  // };

  // const handleSubscription = () => {
  //   if (emailInfo === "") {
  //     setErrMsg("Please provide an Email !");
  //   } else if (!emailValidation(emailInfo)) {
  //     setErrMsg("Please give a valid Email!");
  //   } else {
  //     setSubscription(true);
  //     setErrMsg("");
  //     setEmailInfo("");
  //   }
  // };
  let iconYouTubeStyles = { color: "#FF0000", fontSize: "1.4em" };
  let iconFbStyles = { color: "#0866FF", fontSize: "1.4em" };
  let iconLinkdinStyles = { color: "#0a66c2", fontSize: "1.4em" };
  let iconWhatsAppStyles = { color: "#40c351", fontSize: "1.4em" };
  let iconInstagramStyles = { color: "#E1306C", fontSize: "1.4em" };

  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title=" More about RK Global Trader" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%] text-justify">
              As we continue to write the story of RK Global Trader, we invite
              you to join us on this exciting journey. Explore new markets,
              expand your horizons, and experience the unparalleled support of a
              dedicated global trade partner.
            </p>
            <div className="flex items-center gap-2">
              <a
                href="https://www.youtube.com/channel/UCEF5TbZReaHEcHKs36ceqXw"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Youtube and see more"
              >
                <span className="w-7 h-7  hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaYoutube style={iconYouTubeStyles} />
                </span>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61554237851124"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Facebook and see more"
              >
                <span className="w-7 h-7  hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaFacebook style={iconFbStyles} />
                </span>
              </a>
              <a
                href="https://www.linkedin.com/company/rkglobaltraderexportimport"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Linkedin and see more"
              >
                <span className="w-7 h-7  hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaLinkedin style={iconLinkdinStyles} />
                </span>
              </a>
              <a
                href="https://www.instagram.com/rkglobaltrader_export_import/"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Instagram and see more"
              >
                <span className="w-7 h-7  hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaInstagram style={iconInstagramStyles} />
                </span>
              </a>
              <a
                href="https://wa.me/+919860968617"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Whatsapp and see more"
              >
                <span className="w-7 h-7  hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaWhatsapp style={iconWhatsAppStyles} />
                </span>
              </a>
            </div>
          </div>
        </div>

        <div>
          <FooterListTitle title="Product Range" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <Link
                to="/trade"
                state={{ data: location.pathname.split("/")[1] }}
              >
                Agricultural
              </Link>
            </li>
            <li className="hidden font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Pharmaceuticals
            </li>
            <li className="hidden font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Textiles
            </li>
            <li className="hidden font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Plastic and linoleum
            </li>
            <li className="hidden font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Engineering goods
            </li>
          </ul>
          <br />
          <FooterListTitle title="Portfolio" />
          <ul className="flex flex-col gap-2">
            <li className=" font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <a href="https://www.exportbureau.com/company_report.html?code=191482&name=rk_global_trader" target="_blank">
                <img src="https://www.exportbureau.com/images/link/icon135x30approved.gif" width="135" height="30" border="0" />
              </a>
            </li><br/>
            <li className=" font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <a href="https://rkglobaltrader.linker.store/" title="Ratnadip Kokate" target="_blank" glair-click="">
                <div class="mgt_logo">
                  <img src="https://gl-t.linker-cdn.net/images/www/glv2/gl_logo.svg" />
                </div>
                <p class="mgt_txt font_16 fw-500 text-center m-0">Business Profile</p>
              </a>
            </li>
          </ul>
        </div>

        <div>
          <FooterListTitle title="Branch" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">

              <Link
                to="/contact"
                state={{ data: location.pathname.split("/")[1] }}
              >
                Pune, India
              </Link>
            </li>
            {/* <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Orders
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Addresses
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Account Details
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Payment Options
            </li> */}
          </ul>
          <br />
          <FooterListTitle title="Company" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <Link
                to="/about"
                state={{ data: location.pathname.split("/")[1] }}
              >
                <span>About</span>
              </Link>
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <Link
                to="/whychooseus"
                state={{ data: location.pathname.split("/")[1] }}
              >
                Why Choose Us
              </Link>

            </li>
            <li className="hidden font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Our Clients
            </li>
          </ul>
        </div>

        <div className="col-span-2 flex flex-col items-left w-full px-4">
          <FooterListTitle title="Stay Connected" />
          {/* <div className="w-full">
            <p className="text-center mb-4 text-justify">
              Stay informed about the latest developments in international trade, market insights, and exclusive offers by subscribing to RK Global Trader's newsletter. Subscribe through the Email.
            </p>
            {subscription ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base font-titleFont font-semibold text-green-600"
              >
                Subscribed Successfully !
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder="Insert your email ...*"
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSubscription}
                  className="bg-white text-lightText w-[30%] h-10 hover:bg-black hover:text-white duration-300 text-base tracking-wide"
                >
                  Subscribe
                </button>
              </div>
            )}

            <Image
              className={`w-[80%] lg:w-[60%] mx-auto ${
                subscription ? "mt-2" : "mt-6"
              }`}
              imgSrc={paymentCard}
            />
          </div> */}
          <div className="flex items-top gap-2 mb-4">
            <div className="mt-1">
              <FaMapMarkerAlt />
            </div>
            <div>
              <p>Registered address:</p>
              <p>
              Gat No-720,721,723(P),733, <br />
              FERONIA-506,Nyati Elan <br />
              Wagholi,Pune <br />
              Maharashtra - 412207, India.
              </p>
            </div>
          </div>
          <div className="flex items-top gap-2 mb-4">
            <div className="mt-1">
              <FaPhoneAlt />
            </div>
            <div>
              <p>Phone Number:</p>
              <a className="phonenumber" href="tel:+91 98609 68617">+91 98609 68617</a><br />
              <a className="phonenumber" href="tel:+91 88888 04149">+91 88888 04149</a>
            </div>
          </div>
          <div className="flex items-top gap-2 mb-4">
            <div className="mt-1">
              <FaEnvelope />
            </div>
            <div>
              <p>Email:</p>
              <a className="email" href="mailto:sales@rkglobaltrader.com">sales@rkglobaltrader.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
