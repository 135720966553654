import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../designLayouts/Image";
import Heading from "../Products/Heading";


const Sale = (currentSales, props) => {
  const navigate = useNavigate();



  function FirstItems({ currentSales }) {
    const navigate = useNavigate();
    const productItem = currentSales.currentSales[0];
    const _id = currentSales.currentSales[0].productName;

    const idString = (_id) => {
      return String(_id).toLowerCase().split(" ").join("");
    };
    const rootId = idString(_id);

    const handleProductDetails = () => {
      navigate(`/product/${rootId}`, {
        state: {
          item: productItem,
        },
      });
    };
    return (
      <div className="w-full md:w-2/3 lg:w-1/2 h-full" onClick={handleProductDetails}>
        {currentSales &&
          currentSales.currentSales.map((item, index) => index === 0 ?
            <Image className="h-full w-full object-cover" key={index} imgSrc={item.img} />
            : ''
          )}
      </div>
    );
  }

  function SecondItems({ currentSales }) {

    const handleSecondProductDetails = (index) => {
      const productItem = currentSales.currentSales[index];
      const _id = currentSales.currentSales[index].productName;
      const idString = (_id) => {
        return String(_id).toLowerCase().split(" ").join("");
      };
      const rootId = idString(_id);
      navigate(`/product/${rootId}`, {
        state: {
          item: productItem,
        },
      });
    };

    return (
      <>
        {currentSales &&
          currentSales.currentSales.map((item, index) => index === 1 || index === 2 ?
            <div key={index} className="h-1/2 w-full" onClick={() => handleSecondProductDetails(index)}>
              <Image
                className="h-full w-full object-cover"
                imgSrc={item.img}
              />
            </div>
            : ''
          )}
      </>
    );
  }
  return (
    <>
    <Heading heading="Rice , Groundnuts & Walnuts and Honey" />
    <div className="py-2 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
      <FirstItems currentSales={currentSales} />
      <div className="w-full md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-10">
        <SecondItems currentSales={currentSales} />
      </div>
    </div>
    </>
  );
};

export default Sale;
