import React from "react";
import ImageZoom from "react-image-zooom";

const Certificate = (props) => {
  return (
    <div className="w-full relative group border-[1px]">
      <div className="max-w-80 max-h-25 relative overflow-y-hidden ">
        <div>
          <ImageZoom src={props.img} alt="Certificate" zoom="300" />
        </div>
      </div>
      <div className="max-w-80 py-6 flex flex-col gap-1 border-t-0 px-4 border-t-2">
        <div className="flex items-center justify-between font-titleFont">
          <h2 className="text-lg text-primeColor font-bold">
            {props.productName} <br/>
          </h2>
          <a className="text-[#767676] text-[14px]" href={'http://'+ props.price} target="_blank">{props.price}</a>
        </div>
        <div>
          <p className="text-[#767676] text-[14px]" >Number : {props.color}</p>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
