import React from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { Helmet } from "react-helmet";

const Whychooseus = () => {

  return (
    <div className="max-w-container mx-auto px-4">
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Why Choose Us - RK Global Trader</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="description" content="Discover why RK Global Trader Export Business is the ideal choice for your international trade needs. Explore our commitment to quality, reliability, and excellence in global commerce." />
        <meta name="keywords" content="export business, international trade, business excellence, quality exports, reliable partner, global commerce, customer satisfaction, Export from india, Export website, Import website, Trading website, International Trading website, Export Product"></meta>
        <meta name="author" content="RK Global Trader | Agriculture Product | Export from India"></meta>
        <link ref="canonical" href="http://www.rkglobaltrader.com/whychooseus"></link>
      </Helmet>
      <Breadcrumbs title="Why Choose Us"/>
      <div className="pb-10">
        <div className="pl-11 pr-11 mb-4">
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            RK Global Trader supplies only the real thing to our customers and we are dedicated to offering fresh and healty fruits and vegetables to our customers.
            <br />
            All our products are fully certified organic, grown according to international standards, and packed in compostable and renewable packaging materials.
            <br />
            We have the highest quality standards which all our produce must meet in order to be fit for your table.<br /><br />
            If you're looking for choose RK Global Trader for export from India, you may want to consider the following general criteria
            and inquire directly with the company for more specific information:
          </h1>
          {/* max-w-[600px] */}
        </div>

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">
            Experience in Export Markets:
          </span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            RK Global Trader's experience in the export markets you are
            interested in. Experience in specific regions or industries can be
            valuable for navigating challenges and ensuring compliance.
          </h1>
        </div>

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">
            Comprehensive Services:
          </span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            RK Global Trader offers a comprehensive range of services, including
            logistics, documentation, customs clearance, and other essential
            components of the export process.
          </h1>
        </div>

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">
            Global Network and Partnerships:
          </span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            A well-established network and partnerships can facilitate smoother
            international trade. RK Global Trader has strong connections with
            reliable partners and networks.
          </h1>
        </div>

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">
            Innovative Solutions:
          </span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            RK Global Trader employs innovative solutions and technologies to
            enhance efficiency in the export process.
          </h1>
        </div>

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">
            Communication and Customer Service:
          </span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            Effective communication is crucial in international trade. Evaluate RK Global Trader's responsiveness and the quality of their customer service.
          </h1>
        </div>

        <div className="pl-11 pr-11 pt-1 mb-4">
          <span className="text-primeColor font-semibold text-lg">
            Get in Touch:
          </span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 pt-05 mt-2">
            Ready to explore new horizons with RK Global Trader? Contact us
            today to discover how our expertise, global network, and commitment
            to excellence can elevate your international trade experience.
          </h1>
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            Thank you for choosing RK Global Trader — Where Global Opportunities
            Await!
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Whychooseus;
