import React from "react";
import { Link } from "react-router-dom";

const ProductInfo = ({ productInfo }) => {
  var description = "" + productInfo.des +"";
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      {/* {productInfo.price ? (  
        <p className="text-xl font-normal">
          <span className="font-semibold">Price: </span>$&nbsp;
          {productInfo.price}
        </p>
      ) : (
        ""
      )} */}
      {productInfo.size ? (
        <p className=" font-normal">
          <span className="font-semibold">Size: </span>
          {productInfo.size}
        </p>
      ) : (
        ""
      )}
      {productInfo.color ? (
        <p className="font-normal ">
          <span className="font-semibold">Colors:</span> {productInfo.color}
        </p>
      ) : (
        ""
      )}

      {productInfo.Weight ? (
        <p className="font-normal ">
          <span className="font-semibold">Weight:</span> {productInfo.Weight}
        </p>
      ) : (
        ""
      )}
      {productInfo.Taste ? (
        <p className="font-normal ">
          <span className="font-semibold">Taste:</span> {productInfo.Taste}
        </p>
      ) : (
        ""
      )}
      {productInfo.Varities ? (
        <p className="font-normal ">
          <span className="font-semibold">Varities:</span>{" "}
          {productInfo.Varities}
        </p>
      ) : (
        ""
      )}

      {productInfo.packaging ? (
        <p className="text-base">
          <span className="font-semibold">Packaging: </span>
          {productInfo.packaging}
        </p>
      ) : (
        ""
      )}
      <div className="font-semibold pb-0">Description -</div>
      <div className="text-base font-semibold text-gray-600 text-justify">
            {description.split('\n').map((item, i) => <p key={i}>{item}</p>)}
       </div>
       <Link to="/enquirenow">

      <button
        // onClick={() =>
        //   dispatch(
        //     addToCart({
        //       _id: productInfo.id,
        //       name: productInfo.productName,
        //       quantity: 1,
        //       image: productInfo.img,
        //       badge: productInfo.badge,
        //       price: productInfo.price,
        //       colors: productInfo.color
        //     })
        //   )
        // }
        className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont"
      >
        Enquire Now
      </button>
      </Link>

      {/* flex flex-col gap-4 text-sm lg:text-base text-[#767676] 
      border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300
      */}
      <div className="">
        {productInfo && productInfo.speci && productInfo.speci.map((specification) => (
          <div key={specification._id} className="font-semibold text-lg specification">{productInfo.productName} Specification :-
            <div
              className=""
            >
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._pur}</span>  <span> : {specification.Purity}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._Natural} </span>  <span> :  {specification.Natural_Adm}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._avg} </span>  <span> :  {specification.AverageGrain}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._Moisture} </span>  <span> :  {specification.Moisture}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._BrokenGrain} </span>  <span> : {specification.BrokenGrain}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._DamageGrain} </span>  <span> : {specification.DamageGrain}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._ImmatureGrain} </span>  <span> : {specification.ImmatureGrain}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._ForeignMatter} </span>  <span> : {specification.ForeignMatter}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._PackagingType} </span>  <span> : {specification.PackagingType}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._SupplyAbility} </span>  <span> : {specification.SupplyAbility}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._MainExportMarket} </span>  <span> : {specification.MainExportMarket}</span></div>
              <div className="font-normal text-sm grid grid-cols-2"><span>{specification._DeliveryTime} </span>  <span> : {specification.DeliveryTime}</span></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductInfo;
