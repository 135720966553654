import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import { logo, logoLight } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { navBarList } from "../../../constants";
import Flex from "../../designLayouts/Flex";
import { FaFacebook, FaYoutube, FaLinkedin, FaWhatsapp, FaPhoneVolume, FaEnvelope,FaInstagram } from "react-icons/fa";


const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [category, setCategory] = useState(false);
  const [brand, setBrand] = useState(false);
  const location = useLocation();
  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);
  let iconFbStyles = { color: "#0866FF", fontSize: "1.4em" };
  let iconYouTubeStyles = { color: "#FF0000", fontSize: "1.4em" };
  let iconLinkdinStyles = { color: "#0a66c2", fontSize: "1.4em" };
  let iconWhatsAppStyles = { color: "#40c351", fontSize: "1.4em" };
  let iconInstagramStyles = { color: "#E1306C", fontSize: "1.4em" };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_element"
    );
  };

  useEffect(() => {
    const scriptSourceToCheck = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    // const scriptExists = Array.from(document.scripts).some(script => script.src === scriptSourceToCheck);


    var addScript = document.createElement("script");
    addScript.src = scriptSourceToCheck;
    // addScript.defer = true;
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    // }
  }, []);

  return (
    <div className="w-full h-30 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
      <div id="google_element">
      </div>
      <div className="inner-Top-Header bg-[#F5F5F3] relative">
        <div className="max-w-container mx-auto flex px-4 flex-col md:flex-row justify-between items-center pt-2 pb-1">
          <div className="text-left text-[#ffffff]">
            <div className="flex items-center gap-2">
              <FaPhoneVolume></FaPhoneVolume>
              <a className="phonenumberHeader" href="tel:+91 98609 68617">+91 98609 68617</a> / 
              <a className="phonenumberHeader" href="tel:+91 88888 04149">+91 88888 04149</a>
              <span className="flex items-center gap-2 max-[600px]:text-black max-[600px]:hidden">
                <FaEnvelope></FaEnvelope>
                <a className="mailHeader" href="mailto:contact@rkglobaltrader.com">contact@rkglobaltrader.com</a></span>
            </div>
          </div>
          <div className="col-xs-12 text-right max-[600px]:hidden ">
            <div className="flex items-center gap-2">
              <span>Follow us: </span>
              <a
                href="https://www.youtube.com/channel/UCEF5TbZReaHEcHKs36ceqXw"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Youtube and see more"
              >
                <span className="w-7 h-7 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaYoutube style={iconYouTubeStyles} />
                </span>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61554237851124"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Facebook and see more"
              >
                <span className="w-7 h-7  hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaFacebook style={iconFbStyles} />
                </span>
              </a>
              <a
                href="https://www.linkedin.com/company/rkglobaltraderexportimport"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Linkedin and see more"
              >
                <span className="w-7 h-7 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaLinkedin style={iconLinkdinStyles} />
                </span>
              </a>
              <a
                href="https://www.instagram.com/rkglobaltrader_export_import/"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Instagram and see more"
              >
                <span className="w-7 h-7  hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaInstagram style={iconInstagramStyles} />
                </span>
              </a>
              <a
                href="https://wa.me/+919860968617"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to Whatsapp and see more"
              >
                <span className="w-7 h-7 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-white duration-300">
                  <FaWhatsapp style={iconWhatsAppStyles} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <Flex className="flex items-center justify-between h-full">
          <Link to="/">
            <div>
              <Image className="w-35 object-cover" imgSrc={logo} />
            </div>
          </Link>
          <div>
            {showMenu && (
              <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center w-auto z-50 p-0 gap-2"
              >
                <>
                  {navBarList.map(({ _id, title, link }) => (
                    <NavLink
                      key={_id}
                      className="flex font-normal hover:font-bold w-30 h-6 justify-center items-center text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      to={link}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                      <span>{title}</span>
                    </NavLink>
                  ))}
                </>
              </motion.div>
            )}
            <HiMenuAlt2
              onClick={() => setSidenav(!sidenav)}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />
            {sidenav && (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                >
                  <div className="w-full h-full bg-primeColor p-6">
                    <img
                      className="w-28 mb-6"
                      src={logoLight}
                      alt="logoLight"
                    />
                    <ul className="text-gray-200 flex flex-col gap-2">
                      {navBarList.map((item) => (
                        <li
                          className="font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                          key={item._id}
                        >
                          <NavLink
                            to={item.link}
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                            {item.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-4">
                      <h1
                        onClick={() => setCategory(!category)}
                        className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                      >
                        Trade by Category{" "}
                        <span className="text-lg">{category ? "-" : "+"}</span>
                      </h1>
                      {category && (
                        <motion.ul
                          initial={{ y: 15, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="text-sm flex flex-col gap-1"
                        >
                          <li className="headerSedenavLi">
                            <Link to="/trade"
                              onClick={() => setSidenav(false)}
                            >
                              Agriculture
                            </Link>
                          </li>
                          {/* <li className="headerSedenavLi">Gudgets</li>
                          <li className="headerSedenavLi">Accessories</li>
                          <li className="headerSedenavLi">Electronics</li> */}
                          <li className="headerSedenavLi">
                            <Link to="/"
                              onClick={() => setSidenav(false)}
                            >
                              Others
                            </Link>

                          </li>
                        </motion.ul>
                      )}
                    </div>
                    <div className="mt-4">
                      <h1
                        onClick={() => setBrand(!brand)}
                        className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                      >
                        Trade by Brand
                        <span className="text-lg">{brand ? "-" : "+"}</span>
                      </h1>
                      {brand && (
                        <motion.ul
                          initial={{ y: 15, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="text-sm flex flex-col gap-1"
                        >
                          <li className="headerSedenavLi">
                            <Link to="/"
                              onClick={() => setSidenav(false)}
                            >
                              New Arrivals</Link>
                          </li>
                          {/* <li className="headerSedenavLi">
                            <Link to="/">Gudgets</Link>
                          </li>
                          <li className="headerSedenavLi">
                            <Link to="/">Accessories</Link>
                          </li>
                          <li className="headerSedenavLi">
                            <Link to="/">Electronics</Link>
                          </li> */}
                          <li className="headerSedenavLi">
                            <Link to="/"
                              onClick={() => setSidenav(false)}
                            >Others</Link>
                          </li>
                        </motion.ul>
                      )}
                    </div>
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
          </div>
        </Flex>
      </nav>
    </div>
  );
};

export default Header;
