import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
} from "../../../assets/images/index";

const SpecialOffers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Special Products" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1101"
          img={spfOne}
          productName="Red Onions"
          price="3.03"
          color="Dark Red"
          badge={true}
          packaging="1 kg, 2 kg, 5 kg, 10 kg, 20 kg, Mesh Bag"
          size="30 mm to 50 mm and 50 mm to 60 mm"
          des="Fresh Onions Exporters from India"
        />
        <Product
          _id="1102"
          img={spfTwo}
          productName="Carrot"
          price="3.92"
          color="Orange"
          badge={true}
          des="Orange carrots are rich in beta-carotene, an antioxidant that the body converts into vitamin A. They also contain other essential nutrients such as vitamin K, vitamin C, potassium, and dietary fiber."
          Taste="sweet "
          Varities="Orange Carrot"
          size="6 to 8 inches long"
        />
        <Product
          _id="1103"
          img={spfThree}
          productName="Cucumber "
          price="3.00"
          color="Green"
          badge={true}
          des="Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium."
          Taste="Cucumbers have a mild, refreshing taste."
          Varities="English cucumbers, Persian cucumbers, and standard American slicing cucumbers."
          size="6 to 9 inches in length"
        />
        <Product
          _id="1104"
          img={spfFour}
          productName="Mixed Chillies"
          price="0.00"
          color="Green and Red"
          badge={false}
          size="5 to 10 cm"
          packaging="3 kg to 5 kg Carton Box"
          des="Fresh Green Chilies Exporters from India"
        />
      </div>
    </div>
  );
};

export default SpecialOffers;
