import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { useNavigate } from "react-router-dom";
import { currentSpices } from "../../../constants";


const Spices = () => {
  const navigate = useNavigate();
  function SpicesItems({ currentspice }) {
    const handleSpicesProductDetails = (index) => {
      const productItem = currentspice[index];
      const _id = currentspice[index].productName;
      const idString = (_id) => {
        return String(_id).toLowerCase().split(" ").join("");
      };
      const rootId = idString(_id);
      navigate(`/product/${rootId}`, {
        state: {
          item: productItem,
        },
      });
    };

    return (
      <>
        {currentspice &&
          currentspice.map((spice, index) =>
            <div key={index} className="h-1/2 w-full" onClick={() => handleSpicesProductDetails(index)}>
              <Product
                _id={spice._id}
                img={spice.img}
                productName={spice.productName}
                price={spice.price}
                color={spice.color}
                des={spice.des}
              />
            </div>
          )}
      </>
    );
  }


  return (
    <div className="w-full pb-6 pt-4">
      <Heading heading="Spices" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <SpicesItems currentspice={currentSpices} />
      </div>
    </div>
  );
};

export default Spices;
