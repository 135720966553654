
import React, { useState, useEffect } from "react";
import CertificatePagination from "../../components/pageProps/shopPage/CertificatePagination";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Certificate = () => {
  const [itemsPerPage, setItemsPerPage] = useState(6);
  // const [categoryName, setCategoryName] = useState("");

  // const itemsPerPageFromBanner = (itemsPerPage) => {
  //   setItemsPerPage(itemsPerPage);
  // };
  // const item = (catName) => {
  //   if (catName) {
  //     setCategoryName(catName);
  //   }
  // };
  useEffect(() => {
    // item();
  });
  return (
    <div className="max-w-container mx-auto px-4">
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Export Certificates - RK Global Trader</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="description" content="Explore and download certificates related to our export business. Certificates ensure the quality and compliance of our products for international trade." />
        <meta name="keywords" content="RK Global Trader, export certificates, product quality assurance, international trade compliance, export documentation, business certifications, Export from india, Export website, Import website, Trading website, International Trading website, Export Product"></meta>
        <meta name="author" content="RK Global Trader | Agriculture Product | Export from India"></meta>
        <link ref="canonical" href="http://www.rkglobaltrader.com/certificate"></link>
      </Helmet>
      <Breadcrumbs title="Certificates" />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        {/* <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <CertificateSideNav items={item} />
        </div> */}
        <div className="w-full mdl:w-[80%] lgl:w-[80%] h-full flex flex-col gap-10 m-auto">
          {/* <CertificateBanner itemsPerPageFromBanner={itemsPerPageFromBanner} /> */}
          <CertificatePagination
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Certificate;
