import React, { useState } from "react";
// import { FaPlus } from "react-icons/fa";
import { ImPlus ,ImMinus } from "react-icons/im";
import NavTitle from "./NavTitle";
import { motion } from "framer-motion";

const Category = () => {
  const [showSubCatOne, setShowSubCatOne] = useState(false);
  const [showSubCatTwo, setShowSubCatTwo] = useState(false);
  const items = [
    {
      _id: 990,
      title: "Agriculture",
      icons: true,
    },
    // {
    //   _id: 991,
    //   title: "Gudgets",
    // },
    // {
    //   _id: 992,
    //   title: "Accessories",
    //   icons: true,
    // },
    // {
    //   _id: 993,
    //   title: "Electronics",
    // },
    {
      _id: 994,
      title: "Others",
    },
  ];

  const colors = [
    {
      _id: 9001,
      title: "Green",
      base: "#22c55e",
    },
    {
      _id: 9002,
      title: "Gray",
      base: "#a3a3a3",
    },
    {
      _id: 9003,
      title: "Red",
      base: "#dc2626",
    },
    {
      _id: 9004,
      title: "Yellow",
      base: "#f59e0b",
    },
    {
      _id: 9005,
      title: "Blue",
      base: "#3b82f6",
    },
  ];

  const agricultureProduct = [
    {
      _id: 1,
      title: "Green Chilli",
      base: "#4f4b4b",
    },
    {
      _id: 2,
      title: "Red Chilli",
      base: "#4f4b4b",
    },
    {
      _id: 3,
      title: "Onion",
      base: "#4f4b4b",
    },
    {
      _id: 4,
      title: "Red Rose Onion",
      base: "#4f4b4b",
    },
    {
      _id: 5,
      title: "Cucumber",
      base: "#4f4b4b",
    },
  ];
  const handleClick = (event) => {
    if (event.currentTarget.title === "Agriculture") {
      setShowSubCatOne(!showSubCatOne);
    } else if (event.currentTarget.title === "Accessories") {
      setShowSubCatTwo(!showSubCatTwo);
    }
  };
  return (
    <div className="w-full">
      <NavTitle title="Trade by Category" icons={false} />
      <div>
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {items.map(({ _id, title, icons }) => (
            <li
              key={_id}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2  items-center justify-between"
            >
              <div className="titleLabel text-gray-800">
                {title}
                {icons && (
                  <span
                    onClick={handleClick}
                    className="text-[10px] lg:text-xs cursor-pointer text-gray-800 hover:text-primeColor duration-300"
                    title={title}
                  >
                    {title === "Agriculture" && !showSubCatOne &&  <ImPlus />}
                    {title === "Agriculture" && showSubCatOne && <ImMinus/>}
                    {title === "Accessories" && !showSubCatTwo &&  <ImPlus />}
                    {title === "Accessories" && showSubCatTwo && <ImMinus/>}
                  </span>
                )}
              </div>
              {title === "Agriculture" && showSubCatOne && (
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#050505]">
                    {agricultureProduct.map((item) => (
                      <li
                        key={item._id}
                        className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2"
                      >
                        <span
                          style={{ background: item.base }}
                          className={`w-3 h-2 bg-gray-500`}
                        ></span>
                        {item.title}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              )}

              {title === "Accessories" && showSubCatTwo && (
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
                    {colors.map((item) => (
                      <li
                        key={item._id}
                        className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2"
                      >
                        <span
                          style={{ background: item.base }}
                          className={`w-3 h-3 bg-gray-500 rounded-full`}
                        ></span>
                        {item.title}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Category;
