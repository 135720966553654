import React from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { ratnadip, monika, rajesh } from "../../assets/images";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { Helmet } from "react-helmet";

const About = () => {
  let iconLinkdinStyles = { color: "#0a66c2", fontSize: "1.4em" };
  let iconFbStyles = { color: "#0866FF", fontSize: "1.4em" };

  return (
    <div className="max-w-container mx-auto px-4">
      <Helmet>
        <title>About Us - RK Global Trader</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta charSet="utf-8"></meta>
        <meta name="description" content="Learn about our export business and our commitment to providing high-quality products worldwide." />
        <meta name="keywords" content="RK Global Trader, export business, international export and import, global commerce, product export, business history, Export from india, Export website, Import website, Trading website, International Trading website, Export Product"></meta>
        <meta name="author" content="RK Global Trader | Agriculture Product| Export from India"></meta>
        <link ref="canonical" href="http://www.rkglobaltrader.com/about"></link>
      </Helmet>
      <Breadcrumbs title="About" />
      <div className="pb-10">

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">About RK Global Trader</span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            Welcome to RK Global Trader, a dynamic player in the export and import industry, dedicated
            to providing seamless global export and import solutions. At RK Global Trader, we believe in fostering
            connections, driving economic growth, and delivering value through our commitment to excellence. <br />
            <p>
              Exporting goods from India involves a diverse range of products, reflecting the country's economic and industrial diversity.
              We are exporting and importing services in agricultural products, engineering goods, and iron and steel.
            </p>
          </h1>
          {/* max-w-[600px] */}
        </div>

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg">Our Team:</span>{" "}

          <div className="mt-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">

            <div className="max-w-container mx-auto px-4 border-[1px] pt-2">
              <div className="mb-4 pt-3">
                <img src={ratnadip} alt="Ratnadip K. - Sales Operation" width="80%" className="m-auto" />
                <div className="pt-3 text-base font-semibold">Mr.Ratnadip Kokate</div>
                <div className="text-xs">Sales Operation</div>
                <ul className="plr-2 mt-4">
                  <li className="list-disc text-sm">
                    Started RK Global Trader in Nov 2023
                  </li>
                </ul>
                <div className="text-base">Email - rkokate@rkglobaltrader.com</div>
                <div className="text-base">              <a
                  href="https://www.linkedin.com/in/ratnadipkokate/"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex"
                >LinkedIn - /ratnadipkokate
                  <FaLinkedin style={iconLinkdinStyles} />
                </a>
                  <br />
                  <a
                    href="https://www.facebook.com/KokateRatnadip"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex"
                  >
                    Facebook - /KokateRatnadip
                    <FaFacebook style={iconFbStyles} />
                  </a>
                </div>
              </div>
            </div>

            <div className="max-w-container mx-auto px-4 border-[1px]">
              <div className="mb-4 pt-3">
                <img src={monika} alt="Dr.Monika K. - Sales Operation" width="80%" className="m-auto" />
                <div className="pt-3 text-base font-semibold">Dr.Monika Kokate</div>
                <div className="text-xs">Sales Operation</div>
                <ul className="plr-2 mt-4">
                  <li className="list-disc text-sm">
                    Started RK Global Trader in Nov 2023
                  </li>
                </ul>
                <div className="text-base">Email - pathare.monika@gmail.com</div>
                <div className="text-base">              <a
                  href="https://www.linkedin.com/in/monika-pathre-5a999823b/"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex"
                >LinkedIn -
                  <FaLinkedin style={iconLinkdinStyles} />
                </a> <br />
                  <a
                    href="https://www.facebook.com/monika.pathare.7"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex"
                  >
                    Facebook -
                    <FaFacebook style={iconFbStyles} />
                  </a>
                </div>
              </div>
            </div>

            <div className="max-w-container mx-auto px-4 border-[1px]">
              <div className="mb-4 pt-3">
                <img src={rajesh} alt="Rajesh H. - CHA" width="80%" className="m-auto" />
                <div className="pt-3 text-base font-semibold">Mr.Rajesh Hande</div>
                <div className="text-xs">CHA - Docks Clerk</div>
                <ul className="plr-2 mt-4">
                  <li className="list-disc text-sm">
                    Started RK Global Trader in Dec 2023
                  </li>
                </ul>
                <div className="text-base">Email - rajeshhandeov@gmail.com</div>
                <div className="text-base">
                  <a
                    href="https://www.facebook.com/rajbhau2234"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex"
                  >
                    Facebook -
                    <FaFacebook style={iconFbStyles} />
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="pl-11 pr-11 mb-4">

          <div className="mt-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-10 mdl:gap-4 lg:gap-10">

            <div className="max-w-container mx-auto px-4 border-[1px] pt-2">
              <div className="mb-4 pt-3">
                <span className="text-primeColor font-semibold text-lg ">Our Journey:</span>{" "}
                <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                  Founded in Dec-2023 , RK Global Trader has emerged as a trusted name in the export and import  industry. Our journey began with a vision to simplify global export and import and empower businesses of all sizes to explore new markets. Over the years, we have grown into a dynamic and forward-thinking company, driven by a passion for international commerce.
                </h1>
              </div>
            </div>

            <div className="max-w-container mx-auto px-4 border-[1px]">
              <div className="mb-4 pt-3">
                <span className="text-primeColor font-semibold text-lg ">Our Vision:</span>{" "}
                <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                  At RK Global Trader, we envision a world where borders are bridges, connecting businesses and
                  communities across the globe. Our vision is to be a catalyst for international export and import, enabling
                  our clients to reach new markets and achieve sustainable success.
                </h1>
              </div>
            </div>

            <div className="max-w-container mx-auto px-4 border-[1px]">
              <div className="mb-4 pt-3">
                <span className="text-primeColor font-semibold text-lg ">Who We Are:</span>{" "}
                <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                  RK Global Trader is not just a trading company also it provides services in Exports and imports. we are your strategic partner in the ever-evolving landscape of global commerce. Founded on principles of integrity, innovation, and reliability, we strive to exceed expectations in every aspect of our business.
                </h1>
              </div>
            </div>

            <div className="max-w-container mx-auto px-4 border-[1px]">
              <div className="mb-4 pt-3">
                <span className="text-primeColor font-semibold text-lg ">Our Mission:</span>{" "}
                <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                  Our mission is to empower businesses by simplifying the complexities of international export and import services. We are committed to providing tailored solutions that optimize supply chains, enhance efficiency, and unlock new opportunities for growth.
                </h1>
              </div>
            </div>
          </div>

        </div>

        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">What Sets Us Apart:</span>{" "}
          <ul className="plr-2">
            <li className="list-style-number pt-05">
              <span className="text-primeColor font-semibold text-lg ">Global Network:</span>{" "}
              <h1 className=" text-base text-lightText text-justify mb-2">
                RK Global Trader has built a robust network of partners and collaborators worldwide. This network allows us to navigate diverse markets and offer comprehensive export and import solutions.
              </h1>
            </li>
            <li className="list-style-number pt-05">
              <span className="text-primeColor font-semibold text-lg ">Expertise:</span>{" "}
              <h1 className=" text-base text-lightText text-justify mb-2">
                Our team comprises seasoned professionals with in-depth knowledge of international export and import regulations, logistics, and market dynamics. We leverage this expertise to guide our clients through every stage of the export and import  process.
              </h1>
            </li>
            <li className="list-style-number pt-05">
              <span className="text-primeColor font-semibold text-lg ">Innovation:</span>{" "}
              <h1 className=" text-base text-lightText text-justify mb-2">
                We embrace technological advancements to streamline operations and provide cutting-edge solutions. Our commitment to innovation ensures that our clients stay ahead in the competitive global marketplace.
              </h1>
            </li>
          </ul>

        </div>


        <div className="pl-11 pr-11 mb-4">
          <span className="text-primeColor font-semibold text-lg ">Core Values:</span>{" "}
          <ul className="plr-2">
            <li className="list-style-number pt-05">
              <span className="text-primeColor font-semibold text-lg ">Integrity:</span>{" "}
              <h1 className=" text-base text-lightText text-justify mb-2">
                We uphold the highest ethical standards in all our dealings, fostering trust and transparency with our clients and partners.
              </h1>
            </li>
            <li className="list-style-number pt-05">
              <span className="text-primeColor font-semibold text-lg ">Customer-Centric:</span>{" "}
              <h1 className=" text-base text-lightText text-justify mb-2">
                Our clients are at the heart of everything we do. We prioritize their needs, providing personalized and flexible solutions to ensure their success.
              </h1>
            </li>
            <li className="list-style-number pt-05">
              <span className="text-primeColor font-semibold text-lg ">Continuous Improvement:</span>{" "}
              <h1 className=" text-base text-lightText text-justify mb-2">
                We are dedicated to learning and evolving. Through continuous improvement, we stay agile and adaptive in an ever-changing global business environment.
              </h1>
            </li>
          </ul>
        </div>

        <div className="pl-11 pr-11 pt-1 mb-4">
          <span className="text-primeColor font-semibold text-lg">Get in Touch:</span>{" "}
          <h1 className=" text-base text-lightText text-justify mb-2 pt-05 mt-2">
            Ready to explore new horizons with RK Global Trader? Contact us today to discover how our expertise, global network, and commitment to excellence can elevate your international export and import experience.
          </h1>
          <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
            Thank you for choosing RK Global Trader — Where Global Opportunities Await!
          </h1>
        </div>

      </div>

    </div>
  );
};

export default About;

