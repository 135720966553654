import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  bestSellerFive,
  bestSellerSix,
  bestSellerSeven,
  bestSellerEight,
} from "../../../assets/images/index";

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Fruits and Vegetables" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img={bestSellerOne}
          productName="Fresh Red Tomatoes"
          price="4.00"
          color="Red"
          badge={true}
          size="30 mm, 50 mm, 55 mm"
          packaging="5 kg, 10 kg, 15 kg Carton Box"
          des="Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world."
        />
        {/* <Product
          _id="1013"
          img={bestSellerThree}
          productName="Green Lemon"
          price="1.00"
          color="Green"
          badge={true}
          Varities="Bearss Lemons"
          size="30 mm and 40 mm"
          packaging="10 kg, 20 kg"
          des="Fresh Lemons Lemon Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world."
        /> */}
        <Product
          _id="1014"
          img={bestSellerFour}
          productName="Fresh Green Chillies"
          price="3.70"
          color="Green"
          badge={false}
          size="5 to 10 cm"
          packaging="3 kg to 5 kg Carton Box"
          des="Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world."
        />
        <Product
          _id="1014"
          img={bestSellerFive}
          productName="Fresh Pomegranate"
          price="1.98"
          color="Dark red "
          badge={false}
          size=""
          Weight="250 gm+ 400 gm"
          Taste="Sweet"
          Varities="Bhagwa, Arkata, Ganesh "
          packaging="3 kg, 5 kg, 10 kg, Carton Box "
          des="Fresh Pomegranate is one of the important fruit crops commercially grown. The main commercially grown varieties are Bhagwa , Arkata & Ganesh, Bhagwa is widely known for its soft seed, Dark red colour, and extremely delicious.
          Fresh Pomegranate Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa, Germany, UK, Sweden, Italy, USA, Australia, Canada and all over world."
        />

        <Product
          _id="1014"
          img={bestSellerSix}
          productName="Yellow Lemon"
          price="1.73"
          color="Yellow"
          badge={false}
          Varities="Lisbon Lemons"
          size="30 mm and 40 mm"
          packaging="10 kg, 20 kg"
          des="Fresh Lisbon Lemons Exporters from India."
        />
        {/* <Product
          _id="1014"
          img={bestSellerSeven}
          productName="Yellow Mango"
          price="02.10"
          color="Light Yellow"
          badge={false}
          size="250 gm to 400 gm Kesar Mangoes, 200 gm to 300 gm Alphonso Mangoes"
          Taste="Sweet"
          Varities="Kesar and Alphonso"
          packaging=""
          des="Mango is one of the most famous fruits of India. Export and supplier of Alphanso and kesar Mango. It is known as “King of fruits”."
        /> */}
        {/* <Product
          _id="1014"
          img={bestSellerEight}
          productName="Mixed Lemon"
          price="0.00"
          color="Green and Yellow"
          badge={false}
          Varities="Bearss Lemons and Lemonade"
          size="30 mm and 40 mm"
          packaging="10 kg, 20 kg"
          des="Fresh Mixed Lemon Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world."
        /> */}
      </div>
    </div>
  );
};

export default BestSellers;
