import React from "react";
import { BiCaretDown , BiCaretUp } from "react-icons/bi";

const NavTitle = ({ title, icons, showicon }) => {
  return (
    <div className="flex items-center justify-between pb-5">
      {icons ? (
        <>
          <h3 className="font-bold lg:text-xl text-primeColor">{title}</h3>
          {icons && showicon && <BiCaretDown />}
          {icons && !showicon && <BiCaretUp />}
        </>
      ) : (
        <>
          <h3 className="font-bold lg:text-xl text-primeColor">{title}</h3>
        </>
      )}
    </div>
  );
};

export default NavTitle;
