import React from "react";

const ProductDescription = ({ productInfo }) => {
  function ProductDescriptions({ productInfo }) {

    return (
      <>
        {productInfo &&
          productInfo.map((item, index) => item ?
            <div className="w-full relative group" key={item._id}>
              <div
                className=" gap-4 border-b-[1px] border-b-gray-300 py-2 grid grid-cols-4 gap-4"
              >
                <div className="pdptypesimg">
                  <img className="" src={item.img} alt={item.img} />
                </div>
                <div className="flex flex-col gap-2 font-titleFont col-span-3">
                  <p className="text-base font-medium">{item.productName}</p>
                  <p className="text-sm font-semibold">{item.des}</p>
                  {item.types && item.types.length > 0 && 
                  <p className="text-base font-medium">Types of {item.productName} -  </p>
                   }
                  {item.types && item.types.map((types, index1) => types ?
                    <div key={types._id} className="flex flex-col gap-2 font-titleFont pl-4">
                      <p className="text-base font-medium mb-0">{index1 + 1} - {types.productName} : </p>
                      <p className="text-sm ">{types.des}</p>
                    </div>
                    : '')}
                </div>
              </div>

            </div>
            : ''
          )}
      </>
    );
  }
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-10 mdl:gap-4 lg:gap-10">
      <ProductDescriptions productInfo={productInfo.categ} />
    </div>
  );
};

export default ProductDescription;
