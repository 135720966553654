import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  KajuPenuts,
  RoastedPenuts,
  TikhaPeanuts,
} from "../../../assets/images/index";

const Processfood = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Processed Food" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">

        <Product
          _id="1102"
          img={RoastedPenuts}
          productName="Roasted Penuts"
          price=""
          color="Rich Golden Brown"
          badge={false}
          des="Indulge in the delightful crunch and irresistible flavor of Bharosh Roasted Peanuts 35/40 Super Bold. These premium quality peanuts are carefully selected and roasted to perfection, offering a savory and satisfying snacking experience. Super Bold variety: Each peanut is plump and generously sized, ensuring a hearty bite every time.
          Roasted to perfection: Expertly roasted to enhance their natural flavor and achieve a crunchy texture.
          High quality: Selected from the finest peanuts to guarantee superior taste and freshness.
          Versatile snack: Perfect for enjoying on their own as a nutritious snack or adding to your favorite recipes for an extra crunch."
          Taste="Nutty flavor with a hint of sweetness and a satisfying crunch"
          Varities="Super Bold - 35/40"
          size=""
        />
        <Product
          _id="1103"
          img={TikhaPeanuts}
          productName="Tikha Peanuts "
          price=""
          color="Reddish-Brown"
          badge={false}
          des="Savor the bold and spicy flavor of Bharosh Tikha Peanuts 35/40 Super Bold. These premium quality peanuts are carefully selected for their plumpness and roasted to perfection, then expertly coated in a tantalizing blend of spices to create a truly irresistible snack. Super Bold variety: Each peanut is generously sized, ensuring a hearty crunch with every bite.
          Spicy and flavorful: Coated in a zesty blend of spices, including chili powder, cumin, and other aromatic seasonings, for an intense and satisfying taste sensation.
          Expertly roasted: The peanuts are roasted to perfection to enhance their natural flavor and achieve a crunchy texture that perfectly complements the spicy coating.
          High quality: Selected from the finest peanuts to guarantee superior taste and freshness."
          Taste="Spicy twist"
          Varities="Super Bold - 35/40"
          size=""
        />
        <Product
          _id="1101"
          img={KajuPenuts}
          productName="Kaju Penuts"
          price=""
          color="Light beige or cream color for the cashews"
          badge={false}
          packaging=""
          size=""
          Varities="Super Bold - 35/40"
          des="Indulge in the exquisite combination of crunchy peanuts and creamy cashews with Bharosh Kaju Peanuts 35/40 Super Bold. These premium quality nuts are meticulously selected for their size and roasted to perfection, offering a delightful fusion of flavors and textures in every bite. Super Bold variety: Each peanut and cashew is generously sized, ensuring a satisfying crunch and creamy richness.
          Perfectly roasted: Expertly roasted to enhance their natural flavors and achieve a crunchy texture that complements the creaminess of cashews.
          Premium quality: Selected from the finest nuts to guarantee superior taste and freshness.
          Versatile snack: Ideal for enjoying on its own as a wholesome snack or incorporating into your favorite recipes for an extra layer of flavor and texture."
        />
                <Product
          _id="1103"
          img={TikhaPeanuts}
          productName="Tikha Peanuts "
          price=""
          color="Reddish-brown"
          badge={false}
          des="Savor the bold and spicy flavor of Bharosh Tikha Peanuts 35/40 Super Bold. These premium quality peanuts are carefully selected for their plumpness and roasted to perfection, then expertly coated in a tantalizing blend of spices to create a truly irresistible snack. Super Bold variety: Each peanut is generously sized, ensuring a hearty crunch with every bite.
          Spicy and flavorful: Coated in a zesty blend of spices, including chili powder, cumin, and other aromatic seasonings, for an intense and satisfying taste sensation.
          Expertly roasted: The peanuts are roasted to perfection to enhance their natural flavor and achieve a crunchy texture that perfectly complements the spicy coating.
          High quality: Selected from the finest peanuts to guarantee superior taste and freshness."
          Taste="Spicy twist"
          Varities="Super Bold - 35/40"
          size=""
        />
        {/* <Product
          _id="1104"
          img={spfFour}
          productName="Mixed Chillies"
          price="0.00"
          color="Green and Red"
          badge={false}
          size="5 to 10 cm"
          packaging="3 kg to 5 kg Carton Box"
          des="Fresh Green Chilies Exporters from India"
        /> */}
      </div>
    </div>
  );
};

export default Processfood;
