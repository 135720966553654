import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import Cereal from "../../components/home/Cereal/Cereal";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import Processfood from "../../components/home/Processfood/Processfood";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import { Helmet } from "react-helmet";
import { currentSales } from "../../constants";
import Spices from "../../components/home/Spices/Spices";


const Home = () => {
  return (
    <div className="w-full mx-auto">
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>RK Global Trader - Export and Import Solutions</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="description" content="Welcome to RK Global Trader Export Business - Providing high-quality products for international trade. Explore our range of exports and experience seamless global commerce." />
        <meta name="keywords" content="RK Global Trader, export business, international trade, global commerce, product exports, quality products, business solutions, Export from india, Export website, Import website, Trading website, International Trading website, Export Product"></meta>
        <meta name="author" content="RK Global Trader | Agriculture Product | Export from India"></meta>
        <link ref="canonical" href="http://www.rkglobaltrader.com/"></link>
      </Helmet>
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
        <Spices />
        <Sale currentSales={currentSales}/>
        <BestSellers />
        <YearProduct />
        <SpecialOffers />
        <Cereal/>
        <Processfood />
      </div>
    </div>
  );
};

export default Home;
