import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { FaYoutube, FaLinkedin, FaFacebook, FaInstagram, FaQuora, FaTwitter,FaSkype, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
import Image from "../../components/designLayouts/Image";
import { worldCountries, worldsearoutemap } from "../../assets/images";

const Contact = () => {


  // const [clientName, setclientName] = useState("");
  // const [email, setEmail] = useState("");
  // const [messages, setMessages] = useState("");

  // ========== Error Messages Start here ============
  // const [errClientName, setErrClientName] = useState("");
  // const [errEmail, setErrEmail] = useState("");
  // const [errMessages, setErrMessages] = useState("");
  // ========== Error Messages End here ==============
  const [successMsg, setSuccessMsg] = useState("");

  // const handleName = (e) => {
  //   setclientName(e.target.value);
  //   setErrClientName("");
  // };
  // const handleEmail = (e) => {
  //   setEmail(e.target.value);
  //   setErrEmail("");
  // };
  // const handleMessages = (e) => {
  //   setMessages(e.target.value);
  //   setErrMessages("");
  // };

  // ================= Email Validation start here =============
  // const EmailValidation = (email) => {
  //   return String(email)
  //     .toLowerCase()
  //     .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  // };
  // ================= Email Validation End here ===============

  // const handlePost = (e) => {
  //   e.preventDefault();
  //   if (!clientName) {
  //     setErrClientName("Enter your Name");
  //   }
  //   if (!email) {
  //     setErrEmail("Enter your Email");
  //   } else {
  //     if (!EmailValidation(email)) {
  //       setErrEmail("Enter a Valid Email");
  //     }
  //   }
  //   if (!messages) {
  //     setErrMessages("Enter your Messages");
  //   }
  //   if (clientName && email && EmailValidation(email) && messages) {
  //     setSuccessMsg(
  //       `Thank you dear ${clientName}, Your messages has been received successfully. Futher details will sent to you by your email at ${email}.`
  //     );
  //   }
  // };
  let iconLinkdinStyles = { color: "#0a66c2", fontSize: "1.4em" };
  let iconYouTubeStyles = { color: "#FF0000", fontSize: "1.4em" };
  let iconFbStyles = { color: "#0866FF", fontSize: "1.4em" };
  let iconInstagramStyles = { color: "#E1306C", fontSize: "1.4em" };
  let iconFaQuoraStyles = { color: "#AA2200", fontSize: "1.4em" };
  let iconTwitterStyles = { color: "#00ACEE", fontSize: "1.4em" };
  let iconSkypeStyles = { color: "#00aff0", fontSize: "1.4em" };

  return (
    <div className="max-w-container mx-auto px-4">
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Contact Us - RK Global Trader</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="description" content="Contact us for inquiries, support, or collaboration opportunities related to our export business. We are here to assist with international trade and product information." />
        <meta name="keywords" content="RK Global Trader, export business contact, international trade inquiries, business collaboration, customer support, product information, Export from india, Export website, Import website, Trading website, International Trading website, Export Product"></meta>
        <meta name="author" content="RK Global Trader | Agriculture Product | Export from India"></meta>
        <link ref="canonical" href="http://www.rkglobaltrader.com/contact"></link>
      </Helmet>
      <Breadcrumbs title="Contact" />
      {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">{successMsg}</p>
      ) : (
        // <form className="pb-20">
        //   <h1 className="font-titleFont font-semibold text-3xl">
        //     Fill up a Form
        //   </h1>
        //   <div className="w-[500px] h-auto py-6 flex flex-col gap-6">
        //     <div>
        //       <p className="text-base font-titleFont font-semibold px-2">
        //         Name
        //       </p>
        //       <input
        //         onChange={handleName}
        //         value={clientName}
        //         className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
        //         type="text"
        //         placeholder="Enter your name here"
        //       />
        //       {errClientName && (
        //         <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
        //           <span className="text-sm italic font-bold">!</span>
        //           {errClientName}
        //         </p>
        //       )}
        //     </div>
        //     <div>
        //       <p className="text-base font-titleFont font-semibold px-2">
        //         Email
        //       </p>
        //       <input
        //         onChange={handleEmail}
        //         value={email}
        //         className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
        //         type="email"
        //         placeholder="Enter your name here"
        //       />
        //       {errEmail && (
        //         <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
        //           <span className="text-sm italic font-bold">!</span>
        //           {errEmail}
        //         </p>
        //       )}
        //     </div>
        //     <div>
        //       <p className="text-base font-titleFont font-semibold px-2">
        //         Messages
        //       </p>
        //       <textarea
        //         onChange={handleMessages}
        //         value={messages}
        //         cols="30"
        //         rows="3"
        //         className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor resize-none"
        //         type="text"
        //         placeholder="Enter your name here"
        //       ></textarea>
        //       {errMessages && (
        //         <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
        //           <span className="text-sm italic font-bold">!</span>
        //           {errMessages}
        //         </p>
        //       )}
        //     </div>
        //     <button
        //       onClick={handlePost}
        //       className="w-44 bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
        //     >
        //       Post
        //     </button>
        //   </div>
        // </form>

        <div className="pb-10">

          <div className="pl-11 pr-11 mb-4">
            <span className="text-primeColor font-semibold text-lg ">Contact RK Global Trader</span>{" "}
            <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
              We appreciate your interest in RK Global Trader. Whether you have questions about our services, want to explore partnership opportunities, or simply need assistance, we're here to help. Choose the contact method that suits you best:
            </h1>
            {/* max-w-[600px] */}
          </div>
          <div className="pl-11 pr-11 mb-4">

            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
              <div className="max-w-container mx-auto px-4 border-[1px]">
                <div className="mb-4 pt-3">
                  <span className="text-primeColor font-semibold text-lg ">General Inquiries - </span>{" "}
                  <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                    For general inquiries, please feel free to reach out to our dedicated team. We aim to respond to all inquiries promptly.
                    <ul className="plr-2 mt-4">
                      <li className="list-style-circle">
                        <span className="font-bold">Email: </span>
                        <div>
                          info@rkglobaltrader.com
                        </div>
                      </li>
                      <li className="list-style-circle">
                        <span className="font-bold">Phone: </span>
                        <div>
                          [+91-9860968617]
                        </div>
                      </li>
                    </ul>
                  </h1>
                </div>
              </div>
              <div className="max-w-container mx-auto px-4 border-[1px]">
                <div className="mb-4 pt-3">
                  <span className="text-primeColor font-semibold text-lg ">Customer Support - </span>{" "}
                  <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                    Our customer support team is ready to assist you with any questions or concerns related to our services.
                    <ul className="plr-2 mt-4">
                      <li className="list-style-circle">
                        <span className="font-bold">Email: </span>
                        <div>
                          support@rkglobaltrader.com
                        </div>
                      </li>
                      <li className="list-style-circle">
                        <span className="font-bold">Phone: </span>
                        <div>
                          [+91-9860968617]
                        </div>
                      </li>
                    </ul>
                  </h1>
                </div>
              </div>
              <div className="max-w-container mx-auto px-4 border-[1px]">
                <div className="mb-4 pt-3">
                  <span className="text-primeColor font-semibold text-lg ">Visit Us - </span>{" "}
                  <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                    You are welcome to visit our offices during business hours. Please contact us in advance to schedule a meeting.
                    <ul className="plr-2 mt-4">
                      <li className="list-style-circle">
                        <span className="font-bold">Address: </span>
                        Gat No-720,721,723(P),733, FERONIA-506, Nyati Elan, Wagholi-Pune, Maharashtra, India - 412207
                      </li>
                    </ul>
                  </h1>
                </div>
              </div>

              <div className="max-w-container mx-auto px-4 border-[1px]">
                <div className="mb-4 pt-3">
                  <span className="text-primeColor font-semibold text-lg ">Connect With Us on Social Media Platform - </span>{" "}
                  <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                    Stay updated on the latest news, industry trends, Trade and company updates by following us on social media.
                    <ul className="plr-2 mt-4">
                      <li className="list-style-circle pb-2">
                        <span className="font-bold">LinkedIn: </span> <a
                          href="https://www.linkedin.com/company/rkglobaltraderexportimport"
                          target="_blank"
                          rel="noreferrer"
                          className="inline-flex align-bottom"
                        > <FaLinkedin style={iconLinkdinStyles} /></a>
                      </li>
                      <li className="list-style-circle pb-2">
                        <span className="font-bold">Twitter: </span> <a
                          href="https://twitter.com/RKGlobalTrader"
                          target="_blank"
                          rel="noreferrer"
                          className="inline-flex align-bottom"
                        > <FaTwitter style={iconTwitterStyles} /></a>
                      </li>
                      <li className="list-style-circle pb-2">
                        <span className="font-bold">Skype: </span> <a
                          href="https://join.skype.com/invite/y2aKja4bOc03"
                          target="_blank"
                          rel="noreferrer"
                          className="inline-flex align-bottom"
                        > <FaSkype style={iconSkypeStyles} /></a>
                      </li>
                      <li className="list-style-circle pb-2">
                        <span className="font-bold">YouTube: </span><a
                          href="https://www.youtube.com/channel/UCEF5TbZReaHEcHKs36ceqXw"
                          target="_blank"
                          rel="noreferrer"
                          className="inline-flex align-bottom"
                        >
                          <FaYoutube style={iconYouTubeStyles} />
                        </a>
                      </li>
                      <li className="list-style-circle pb-2">
                        <span className="font-bold">Facebook: </span>
                        <a
                          href="https://www.facebook.com/profile.php?id=61554237851124"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Go to Facebook and see more"
                          className="inline-flex align-bottom"
                        >
                          <FaFacebook style={iconFbStyles} />
                        </a>
                      </li>
                      <li className="list-style-circle pb-2">
                        <span className="font-bold">Instagram: </span>
                        <a
                          href="https://www.instagram.com/rkglobaltrader_export_import/"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Go to Instagram and see more"
                          className="inline-flex align-bottom"
                        >
                          <FaInstagram style={iconInstagramStyles} />
                        </a>
                      </li>

                      <li className="list-style-circle pb-2">
                        <span className="font-bold">Quora: </span>
                        <a
                          href="https://www.quora.com/profile/RK-Global-Trader"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Go to Quora and see more"
                          className="inline-flex align-bottom"
                        >
                          <FaQuora style={iconFaQuoraStyles} />
                        </a>
                      </li>

                    </ul>
                  </h1>
                </div>
              </div>

              <div className="max-w-container mx-auto px-4 border-[1px]">
                <div className="mb-4 pt-3">
                  <span className="text-primeColor font-semibold text-lg ">Office Hours - </span>{" "}
                  <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                    Monday to Saturday: 9:00 AM - 11:00 PM (Local Time IST)
                  </h1>
                </div>
              </div>

              <div className="max-w-container mx-auto px-4 border-[1px]">
                <div className="mb-4 pt-3">
                  <span className="text-primeColor font-semibold text-lg ">Feedback -</span>{" "}
                  <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
                    <div>
                      We value your feedback. If you have any suggestions or comments that can help us improve our services, please email us at
                    </div>
                    <div>
                      support@rkglobaltrader.com
                    </div>
                  </h1>
                </div>
              </div>

            </div>

          </div>

          <div className="pl-11 pr-11 mb-4">
            <span className="text-primeColor font-semibold text-lg ">We located in India:</span>{" "}
            <div className="relative">
              <Image className="m-auto" imgSrc={worldCountries} />
              <FaMapMarkerAlt className="icon-location absolute" />
            </div>
          </div>
          <div className="pl-11 pr-11 mb-4">
            <span className="text-primeColor font-semibold text-lg ">Emergency Contact:</span>{" "}
            <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
              For urgent matters outside regular business hours, please contact our emergency hotline at [+919860968617].
            </h1>
            <h1 className=" text-base text-lightText text-justify mb-2 mt-2">
              Thank you for choosing RK Global Trader. We look forward to serving you!
            </h1>
          </div>

          <div className="pl-11 pr-11 mb-4">
            <span className="text-primeColor font-semibold text-lg ">World Sea Routes:</span>{" "}
            <div className="relative">
              <Image className="m-auto" imgSrc={worldsearoutemap} />
              <FaMapMarkerAlt className="sea-location absolute" />
            </div>
          </div>

        </div>

      )}
    </div>
  );
};

export default Contact;
