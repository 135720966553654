import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import ProductDescription from "../../components/pageProps/productDetails/ProductDescription";
import { Helmet } from "react-helmet";

const ProductDetails = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState([]);

  useEffect(() => {
    setProductInfo(location.state.item);
    setPrevLocation(location.pathname);
  }, [location, productInfo]);

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Product Details - RK Global Trader</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="description" content="Explore detailed information about our high-quality export products. Learn about specifications, features, and benefits of our products for international trade." />
        <meta name="keywords" content="export products, product details, international trade, product specifications, product features, product benefits, quality exports, Export from india, Export website, Import website, Trading website, International Trading website, Export Product"></meta>
        <meta name="author" content="RK Global Trader | Agriculture Product | Export from India"></meta>
        <link ref="canonical" href="http://www.rkglobaltrader.com/productdetails"></link>
      </Helmet>
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          {/* <div className="h-full">
            <ProductsOnSale />
          </div> */}
          <div className="h-full xl:col-span-3">
            <img
              className="w-full h-full object-contain pdpimg"
              src={productInfo.img}
              alt={productInfo.img}
            />
          </div>
          <div className="h-full w-full md:col-span-3 xl:col-span-3 px-6 py-2 flex flex-col gap-6 justify-center">
            <ProductInfo productInfo={productInfo} />
          </div>
        </div>
        {
          productInfo.categ && productInfo.categ.length > 1 &&
          <div className="w-full">
            <br/><br/>
            <h1 className="font-semibold underline">Types of {productInfo.productName}</h1>
            <ProductDescription productInfo={productInfo} />
          </div>
        }

      </div>

    </div>
  );
};

export default ProductDetails;
