import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { currentCereal } from "../../../constants";



const Cereal = () => {

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="w-full pb-16">
      <Heading heading="Cereal" />
      <Slider {...settings}>
        {currentCereal &&
          currentCereal.map((item, index) =>
            <div className="px-2" key={index}>
              <Product
                _id={item._id}
                img={item.img}
                productName={item.productName}
                // price="3.03"
                color={item.color}
                // badge={true}
                // size="30 mm to 50 mm and 50 mm to 60 mm"
                packaging={item.packaging}
                des={item.des}
              />
            </div>
          )}
      </Slider>
    </div>
  );
};

export default Cereal;
